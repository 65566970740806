import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateSkincareProps } from './CreateSkincare.types';
import { SkincareProvider } from '../../../../common/context/SkincareContext';
import { Button } from '../../../../common/form/components';
import SkincareModal from '../SkincareModal';

export const CreateSkincare = (props: CreateSkincareProps) => {
  const { consultationId, createMessageCallback, skincare } = props;
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <div>
      <Button
        type="button"
        variant="tertiary"
        label={t('doctor:consultation:skincare:button')}
        onClick={() => {
          setModalVisibility(true);
        }}
      />
      <SkincareProvider>
        <SkincareModal
          consultationId={consultationId}
          createMessageCallback={createMessageCallback}
          visibility={modalVisibility}
          setVisibility={setModalVisibility}
          skincare={skincare}
        />
      </SkincareProvider>
    </div>
  );
};
