import { gql } from '@apollo/client';

export const GET_SKINCARE_PRODUCTS = gql`
  query getSkincareProducts {
    getSkincareProducts {
      id
      product_id
      title
    }
  }
`;
