import { ApolloClient, InMemoryCache, DefaultOptions } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';

const getToken = (): string | null => {
  const storedUserJson = localStorage.getItem('user');
  if (storedUserJson !== null) {
    const storedUser = JSON.parse(storedUserJson);
    return storedUser.access_token;
  }

  return null;
};

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
});

const authLink = setContext((_, { headers }) => {
  const token = getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'X-Temporary-Urls': 'true',
    },
  };
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions,
  connectToDevTools: process.env.REACT_APP_ENV === 'development',
});
