import { useEffect, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import { ActionHeader } from './ActionHeader';
import { HistorySlideOver } from './HistorySlideOver';
import { PatientHeader } from './PatientHeader';
import { User } from '../../../common/authentication/types';
import { SCREEN_MD } from '../../../common/constants/mediaQueries';
import { ICD10Code } from '../../../common/icd10/types';
import { Consultation } from '../../graphql/types';

interface Props {
  consultation: Consultation;
  user: User;
  onSelectConsultation: (consultation: Consultation | null) => void;
  icd10CodeSuggestions: ICD10Code[];
  setConsultationListOpen: (open: boolean) => void;
  consultationUpdateCallback: (updatedConsultation: Consultation) => void;
}

export function ConsultationDetailHeader(props: Props) {
  const {
    consultation,
    user,
    icd10CodeSuggestions,
    onSelectConsultation,
    setConsultationListOpen,
    consultationUpdateCallback,
  } = props;
  const isMdScreen = useMediaQuery(SCREEN_MD);
  const [historySlideOverOpen, setHistorySlideOverOpen] = useState<boolean>(false);

  useEffect(() => {
    setHistorySlideOverOpen(false);
  }, [consultation.id]);

  return (
    <>
      <div
        className="flex flex-col md:flex-row border-b md:items-center divide-y md:divide-y-0 md:divide-x"
        style={isMdScreen ? { height: '183px' } : {}}
      >
        <div className="md:flex-1 h-full">
          <ActionHeader
            user={user}
            consultation={consultation}
            icd10CodeSuggestions={icd10CodeSuggestions}
            onSelectConsultation={onSelectConsultation}
            setListOpen={setConsultationListOpen}
            consultationUpdateCallback={consultationUpdateCallback}
          />
        </div>
        <PatientHeader
          consultation={consultation}
          patient={consultation.patient}
          doctor={consultation.doctor}
          onHistoryButtonClick={() => setHistorySlideOverOpen(true)}
          consultationUpdateCallback={consultationUpdateCallback}
        />
      </div>
      <HistorySlideOver
        isOpen={historySlideOverOpen}
        consultation={consultation}
        onCloseButtonClick={() => setHistorySlideOverOpen(false)}
      />
    </>
  );
}
