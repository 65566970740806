import {
  Doctor,
  AllDoctors,
  Patient,
  AllConsultations,
  ConsultationCheckout,
  FeedbackInput,
  UpdateConsultationInput,
  ConsultationTypeInput,
  Prescription,
  Payment,
  VerifyDoctorInput,
  PatientInvitationInput,
  PatientInvitation,
  ConsultationReadStatus,
  DraftConsultation,
  DraftConsultationInput,
  MessageTextInput,
  MessageImageInput,
  MessageFileInput,
  ValidatedDiscount,
  MatchDoctor,
  MatchDoctorInput,
} from './types';
import { Consultation, ConsultationAttachment, Message } from '../../doctor/graphql/types';
import { ValidationError } from '../api/ValidationError';
import * as api from '../api/api';
import { User } from '../authentication/types';

export async function getDoctor(user: User, id: number): Promise<Doctor> {
  try {
    const doctorResponse = await api.getDoctor(user, id);

    return doctorResponse.data.data.doctor;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function getDoctors(): Promise<AllDoctors> {
  try {
    const doctorsResponse = await api.getDoctors();

    return doctorsResponse.data.data;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function getAllDoctors(user: User): Promise<Doctor[]> {
  try {
    const doctorsResponse = await api.getAllDoctors(user);

    return doctorsResponse.data.data.doctors;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function getDoctorConsultation(user: User, id: string): Promise<Consultation> {
  try {
    const consultationResponse = await api.getDoctorConsultation(user, id);

    return consultationResponse.data.data.consultation;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function getPatientConsultations(user: User): Promise<AllConsultations> {
  try {
    const consultationsResponse = await api.getPatientConsultations(user);

    return consultationsResponse.data.data;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function getPatients(user: User, query: string): Promise<Patient[]> {
  try {
    const patientsResponse = await api.getPatients(user, query);

    return patientsResponse.data.data.patients;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function getPatient(user: User, id: number): Promise<Patient> {
  try {
    const patientResponse = await api.getPatient(user, id);

    return patientResponse.data.data.patient;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function getPreviousConsultations(user: User): Promise<AllConsultations> {
  try {
    const consultationsResponse = await api.getPreviousConsultations(user);

    return consultationsResponse.data.data;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function getConsultation(user: User, id: number): Promise<Consultation> {
  try {
    const consultationResponse = await api.getConsultation(user, id);

    return consultationResponse.data.data.consultation;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function storeConsultationWithDoctorMatching(
  user: User,
  consultationTypeInput: ConsultationTypeInput
): Promise<ConsultationCheckout> {
  try {
    const consultationResponse = await api.storeConsultationWithDoctorMatching(user, consultationTypeInput);
    return consultationResponse.data.data;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function verifyDoctor(user: User, doctor: Doctor, verifyDoctorInput: VerifyDoctorInput): Promise<Doctor> {
  try {
    const verificationResponse = await api.verifyDoctor(user, doctor, verifyDoctorInput);

    return verificationResponse.data.data.doctor;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function closeConsultation(
  user: User,
  consultation: Consultation,
  consultationInput: UpdateConsultationInput
): Promise<Consultation> {
  try {
    const consultationResponse = await api.closeConsultation(user, consultation, consultationInput);

    return consultationResponse.data.data.consultation;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function updateConsultation(
  user: User,
  consultation: Consultation,
  consultationInput: UpdateConsultationInput
): Promise<Consultation> {
  try {
    const consultationResponse = await api.updateConsultation(user, consultation, consultationInput);

    return consultationResponse.data.data.consultation;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function toggleConsultationReadStatus(
  user: User,
  consultation: Consultation,
  consultationReadStatus: ConsultationReadStatus
): Promise<Consultation> {
  try {
    const consultationResponse = await api.toggleConsultationReadStatus(user, consultation, consultationReadStatus);
    return consultationResponse.data.data.consultation;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function updateConsultationByPatient(
  user: User,
  consultationId: number,
  consultationTypeInput: ConsultationTypeInput
): Promise<ConsultationCheckout> {
  try {
    const consultationResponse = await api.updateConsultationByPatient(user, consultationId, consultationTypeInput);
    return consultationResponse.data.data;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function deleteConsultationDraft(user: User, consultationId: number): Promise<Consultation> {
  try {
    const consultationResponse = await api.deleteConsultationDraft(user, consultationId);
    return consultationResponse.data.data;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function storePrescriptions(
  user: User,
  consultationId: string,
  prescriptions: File[]
): Promise<ConsultationAttachment[]> {
  try {
    const prescriptionResponses = await api.storePrescriptions(user, consultationId, prescriptions);

    return prescriptionResponses.map((prescriptionResponse) => {
      return prescriptionResponse.data.data.prescription;
    });
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function deletePrescription(
  user: User,
  consultationId: string,
  prescriptionId: string
): Promise<Prescription> {
  try {
    const prescriptionResponse = await api.deletePrescription(user, consultationId, prescriptionId);

    return prescriptionResponse.data.data.prescription;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function deleteMessage(user: User, consultation: Consultation, message: Message): Promise<Message> {
  try {
    const messageResponse = await api.deleteMessage(user, consultation.id, message.id);

    return messageResponse.data.data.message;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function getMessages(user: User, consultation: Consultation): Promise<Message[]> {
  try {
    const messagesResponse = await api.getMessages(user, consultation);

    return messagesResponse.data.data.messages;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function storeMessage(
  user: User,
  consultationId: string,
  messageInput: MessageTextInput | MessageImageInput | MessageFileInput
): Promise<Message> {
  try {
    const messageResponse = await api.storeMessage(user, consultationId, messageInput);

    return messageResponse.data.data.message;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function storePayment(user: User, consultation: Consultation): Promise<Payment> {
  try {
    const paymentResponse = await api.storePayment(user, consultation);

    return paymentResponse.data.data.payment;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function storeFeedback(
  user: User,
  consultation: Consultation,
  feedbackInput: FeedbackInput
): Promise<Message> {
  try {
    const feedbackResponse = await api.storeFeedback(user, consultation, feedbackInput);

    return feedbackResponse.data.data.feedback;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function storePatientInvitation(
  user: User,
  patientInvitationInput: PatientInvitationInput
): Promise<PatientInvitation> {
  try {
    const patientInvitationResponse = await api.storePatientInvitation(user, patientInvitationInput);

    return patientInvitationResponse.data.data.invitation;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function getDraftConsultation(user: User) {
  try {
    const draftConsultation = await api.getDraftConsultation(user);
    return draftConsultation.data.data.draft_consultation;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function handleDraftConsultationStorage(
  user: User,
  draftConsultationInput: DraftConsultationInput
): Promise<DraftConsultation> {
  try {
    const updateResponse = await api.upsertDraftConsultation(user, draftConsultationInput);
    return updateResponse.data.data.draft_consultation;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function validateDiscount(user: User, consultationId: number, code: string): Promise<ValidatedDiscount> {
  try {
    const validateDiscountResponse = await api.validateDiscount(user, consultationId, code);
    return validateDiscountResponse.data.data;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function matchDoctor(user: User, matchDoctorInput: MatchDoctorInput): Promise<MatchDoctor> {
  try {
    const matchDoctorResponse = await api.matchDoctor(user, matchDoctorInput);
    return matchDoctorResponse.data.data;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}

export async function toggleReopenStatus(user: User, consultation: Consultation): Promise<Consultation> {
  try {
    const toggleReopenStatusResponse = await api.toggleReopenStatus(user, consultation.id);
    return toggleReopenStatusResponse.data.data;
  } catch (error) {
    throw new ValidationError('Validation failed', error.response.data.errors);
  }
}
