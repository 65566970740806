import ConsultationConversation from './ConsultationConversation';
import { ConsultationStatus } from './ConsultationConversation/ConsultationConversation.typs';
import { CreatorHeader } from './CreatorHeader';
import { User } from '../../../common/authentication/types';
import type { Consultation } from '../../graphql/types';

interface Props {
  consultation: Consultation;
  user: User;
  consultationUpdateCallback: (updatedConsultation: Consultation) => void;
}

export function ConsultationDetailConversationView(props: Props) {
  const { consultation, user, consultationUpdateCallback } = props;

  const createCallback = () => {
    consultationUpdateCallback({
      ...consultation,
      status: consultation.status === 'new' ? 'in_progress' : consultation.status,
    });
  };

  return (
    <div className="flex flex-col w-full">
      {consultation.creator && <CreatorHeader patient={consultation.creator} />}
      <ConsultationConversation
        attachments={consultation.attachments ?? []}
        channel={consultation.channel}
        consultationId={consultation.id}
        createCallback={createCallback}
        hasCreator={!!consultation.creator}
        messages={consultation.messages ?? []}
        notes={consultation.consultation_notes ?? []}
        openUntil={consultation.open_until}
        readPermission={!consultation.user_has_write_access}
        status={consultation.status as ConsultationStatus}
        user={user}
        draftMessage={consultation.draftMessage ?? undefined}
        creator={consultation.creator ?? undefined}
        patient={consultation.patient}
        skincare={consultation.skincare ?? undefined}
      />
    </div>
  );
}
