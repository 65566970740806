import { useLazyQuery } from '@apollo/client';
import { PaperClipIcon } from '@heroicons/react/outline';

import type { FileMessageProps } from './FileMessage.types';
import { GET_TEMPORARY_FILE_URL } from '../../../doctor/graphql/queries/message';
import { FileMessage as FileMessageType } from '../../../doctor/graphql/types';
import { extractPathFromUrl } from '../../utils/storage';
import { classNames } from '../../utils/style';
import ConversationWrapper from '../ConversationWrapper';

export function FileMessage(props: FileMessageProps) {
  const { deleteCallbackHandler, message, isUser, readonly } = props;
  const [getTemporaryFileUrl] = useLazyQuery(GET_TEMPORARY_FILE_URL);

  if (message.deleted_at) {
    return null;
  }

  const onClickHandler = async (): Promise<void> => {
    try {
      const path = extractPathFromUrl((message.body as FileMessageType).url);
      const { data, error } = await getTemporaryFileUrl({
        variables: { consultationId: message.consultation_id, path },
      });
      if (data.getTemporaryFileUrl) {
        window.open(data.getTemporaryFileUrl, '_blank');
      } else if (error) {
        handleErrorMessage(error);
      }
    } catch (exception) {
      handleErrorMessage(exception);
    }
  };

  const handleErrorMessage = (error: any) => {
    console.error(error);
  };

  return (
    <ConversationWrapper
      deleteCallbackHandler={deleteCallbackHandler}
      isUser={isUser}
      item={message}
      readonly={readonly}
    >
      <div
        className={classNames(
          'inline-flex items-center px-4 py-2 rounded-lg text-sm leading-5 text-gray-500',
          isUser ? 'bg-indigo-100' : 'bg-gray-100',
          isUser && !readonly && !message.deleted_at && 'pr-8'
        )}
      >
        <button
          className="w-24 md:w-32 lg:w-full lg:max-w-2xl truncate inline-flex items-center"
          onClick={onClickHandler}
        >
          <PaperClipIcon className="flex-shrink-0 h-4 w-4 mt-0.5 mr-1" />
          <span className="underline">{(message.body as FileMessageType).label}</span>
        </button>
      </div>
    </ConversationWrapper>
  );
}
