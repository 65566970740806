import { gql } from '@apollo/client';

export const GET_DOCTOR_CONSULTATIONS = gql`
  query getDoctorConsultations($input: GetDoctorConsultations!) {
    getDoctorConsultations(input: $input) {
      consultations {
        id
        has_new_activity
        status
        consultable_type
        type
        show_problem_pictures
        latest_developments
        created_at
        updated_at
        deadline_at
        consultable {
          ... on Problem {
            id
            description
          }
          ... on CosmeticRequest {
            id
            expectations
          }
        }
        pricing_tier {
          title
        }
        patient {
          avatar
          first_name
          last_name
        }
        creator {
          first_name
          last_name
        }
        user_settings {
          id
          pivot {
            is_flagged
            is_read
          }
        }
      }
      totalCount
    }
  }
`;

export const DOCTOR_CONSULTATION = gql`
  query doctorConsultation($consultationId: ID!) {
    doctorConsultation(consultation_id: $consultationId) {
      attachments {
        id
        name
        file
      }
      channel
      closed_at
      created_at
      icd10_code
      id
      latest_developments
      medication
      latest_consultation
      consultable {
        ... on Problem {
          id
          doctor_treatments
          remarks
          patient_treatments
          discolouration
          other_sensational_symptoms
          sensational_symptoms
          other_visual_symptoms
          visual_symptoms
          description
          first_occurred
          detail_picture
          overview_picture
          pictures {
            picture
          }
        }
      }
      previous_consultation_id
      next_consultation_id
      type
      skin_concern
      status
      pricing_tier {
        title
      }
      patient {
        id
        last_name
        patient_settings {
          allergies
          birth_control
          country
          date_of_birth
          gender
          identification_number
          phone_number
          postal_code
          is_pregnant
        }
        first_name
        email
        consultation_count
        language
      }
      doctor {
        id
        first_name
        last_name
        doctor_settings {
          mediris_integration
          consultation_transfer
        }
      }
      doctor_id
      show_problem_pictures
      creator {
        avatar
        email
        first_name
        id
        last_name
        language
        patient_settings {
          gender
          date_of_birth
          identification_number
          phone_number
        }
      }
      consultation_notes {
        id
        created_at
        deleted_at
        sender: user {
          id
          avatar
          last_name
          first_name
        }
        content
      }
      messages {
        id
        consultation_id
        created_at
        deleted_at
        sender {
          id
          avatar
          first_name
          last_name
        }
        read_receipts {
          status
        }
        body_type
        body {
          ... on TextMessage {
            content
          }
          ... on FileMessage {
            url
            label
          }
          ... on ImageMessage {
            url
          }
        }
      }
      user_settings {
        id
        is_medical_care
        pivot {
          is_flagged
          is_read
          assigned
        }
        avatar
        first_name
        last_name
      }
      draftMessage {
        id
        user_id
        consultation_id
        content
      }
      user_has_write_access
      open_until
      patient_input_summary {
        translations {
          language_code
          content
        }
      }
      skincare {
        intro
        morning
        evening
        additional_tips
        outro
        products {
          id
          product_id
          title
        }
      }
      health_data {
        latest_developments
        medication
        child_desire
        pregnant
        breastfeeding
      }
    }
  }
`;

export const ACTIVE_DOCTORS = gql`
  query activeDoctors {
    activeDoctors {
      id
      first_name
      last_name
    }
  }
`;

export const GET_PATIENT_HISTORY = gql`
  query getPatientHistory($consultationId: ID!) {
    getPatientHistory(consultation_id: $consultationId) {
      created_at
      icd10_code
      id
      doctor {
        first_name
        last_name
      }
      summary {
        translations {
          id
          language_code
          content
        }
      }
      type
      medication
      diagnosis {
        code
        description
      }
      status
      skincare {
        id
        products {
          title
        }
      }
    }
  }
`;
