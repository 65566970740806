import { createContext, Dispatch, Reducer, useContext, useMemo, useReducer } from 'react';

import {
  SearchSkincareProductsAction,
  SearchSkincareProductsProviderProps,
  SearchSkincareProductsState,
} from './SearchSkincareProductsContext.types';

const defaultSearchSkincareProductsState: SearchSkincareProductsState = {
  allProducts: [],
  filteredProducts: [],
  showSearchResults: false,
  searchValue: '',
  selectedProducts: [],
};

const SearchSkincareProductsContext = createContext<
  { state: SearchSkincareProductsState; dispatch: Dispatch<SearchSkincareProductsAction> } | undefined
>(undefined);

const SearchSkincareProductsReducer: Reducer<SearchSkincareProductsState, SearchSkincareProductsAction> = (
  state,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_FIELDS':
      return { ...state, ...payload };
    case 'CLEAR':
      return defaultSearchSkincareProductsState;
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

export const SearchSkincareProductsProvider = ({ children }: SearchSkincareProductsProviderProps) => {
  const [state, dispatch] = useReducer<Reducer<SearchSkincareProductsState, SearchSkincareProductsAction>>(
    SearchSkincareProductsReducer,
    defaultSearchSkincareProductsState
  );

  const contextValue = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  );

  return (
    <SearchSkincareProductsContext.Provider value={contextValue}>{children}</SearchSkincareProductsContext.Provider>
  );
};

export const useSearchSkincareProducts = () => {
  const context = useContext(SearchSkincareProductsContext);
  if (!context) {
    throw new Error('useSearchSkincareProducts must be used within a SearchSkincareProductsProvider');
  }
  return context;
};
