import { XIcon } from '@heroicons/react/solid';

import { SelectedSkincareProductProps } from './SelectedSkincareProduct.types';

export const SelectedSkincareProduct = (props: SelectedSkincareProductProps) => {
  const { product, productRemoveHandler } = props;

  return (
    <span className="px-2 py-0.5 rounded text-xs font-medium leading-4 bg-teal-100 text-teal-800">
      {product.title}
      <button
        onClick={() => {
          productRemoveHandler(product);
        }}
      >
        <XIcon className="ml-1 h-3 w-3 text-teal-400" />
      </button>
    </span>
  );
};
