import { createContext, Dispatch, Reducer, useContext, useMemo, useReducer } from 'react';

import { SkincareAction, SkincareProviderProps, SkincareState } from './SkincareContext.types';

const defaultSkincareState: SkincareState = {
  loading: true,
  intro: '',
  morning: '',
  evening: '',
  additionalTips: '',
  outro: '',
  products: [],
};

const SkincareContext = createContext<
  | {
      state: SkincareState;
      dispatch: Dispatch<SkincareAction>;
    }
  | undefined
>(undefined);

const SkincareReducer: Reducer<SkincareState, SkincareAction> = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_FIELDS':
      return { ...state, ...payload };
    case 'CLEAR':
      return defaultSkincareState;
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

export const SkincareProvider = ({ children }: SkincareProviderProps) => {
  const [state, dispatch] = useReducer<Reducer<SkincareState, SkincareAction>>(SkincareReducer, defaultSkincareState);

  const contextValue = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  );

  return <SkincareContext.Provider value={contextValue}>{children}</SkincareContext.Provider>;
};

export const useSkincare = () => {
  const context = useContext(SkincareContext);
  if (!context) {
    throw new Error('useSkincare must be used within a SkincareProvider');
  }
  return context;
};
