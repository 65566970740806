import { gql } from '@apollo/client';

export const SEND_CONSULTATION_SKINCARE = gql`
  mutation sendConsultationSkincare($input: SendConsultationSkincareInput!) {
    sendConsultationSkincare(input: $input) {
      id
      body {
        ... on TextMessage {
          id
          content
        }
      }
      created_at
      deleted_at
      body_type
      sender {
        id
        last_name
        first_name
        avatar
      }
    }
  }
`;
