import { useContext } from 'react';

import { UserContext } from '../../../common/authentication/UserContext';
import { User } from '../../../common/authentication/types';
import { isReactAppEnvProduction } from '../../../common/utils/env-helper';
import DoctorShell from '../../shell/DoctorShell';

interface DashboardMap {
  [index: number]: string;
}

const doctorDashboardMap: DashboardMap = {
  2: 'https://metabase.skindr.com/public/dashboard/23a86fff-e4e6-434b-8ed2-0a2d07527e95', // Avermaete
  175: 'https://metabase.skindr.com/public/dashboard/eaa28c96-d05a-43a5-8ad3-646bf7e8141d', // Meyvisch
  241: 'https://metabase.skindr.com/public/dashboard/a6f14bf1-cfb8-4a2e-a5af-6ba1268af06a', // Rooms
  629: 'https://metabase.skindr.com/public/dashboard/0a7c5260-b6d8-4a0d-bed0-3fd83c47504a', // Heuvels
  783: 'https://metabase.skindr.com/public/dashboard/aca50f3a-2a8f-4907-8070-c446958df06b', // Heidbuchel
  2184: 'https://metabase.skindr.com/public/dashboard/0f56fd1a-6ddf-41d9-b317-6b1498d66231', // Deleu
  2274: 'https://metabase.skindr.com/public/dashboard/8955fc43-1fbc-459d-9d62-4972ce6ce5ee', // Oris
  4212: 'https://metabase.skindr.com/public/dashboard/0c621bb9-2165-4b79-8f04-1251863e3c59', // Goeteyn
  4445: 'https://metabase.skindr.com/public/dashboard/18bfe984-d7f2-44b9-a186-ee71d668dd33', // Van den Keybus
  4613: 'https://metabase.skindr.com/public/dashboard/2eb7ed5d-9d64-475e-a468-b95168ae0628', // Van Den Bossche
  4688: 'https://metabase.skindr.com/public/dashboard/636b5f64-d77e-49a9-a228-9bfa62572ab5', // Verplancke
  5777: 'https://metabase.skindr.com/public/dashboard/2cb8801f-e0ab-4253-99e0-c5a13807899e', // Sprengers
  5808: 'https://metabase.skindr.com/public/dashboard/bcff3ed8-5462-4539-95ed-22f4a6aa28a5', // Baeyens
  5593: 'https://metabase.skindr.com/public/dashboard/7ef6dbe5-3c0f-49e7-b030-f7d945d52ed1', // Lodewick
  6003: 'https://metabase.skindr.com/public/dashboard/0f50ed1e-467c-4e57-8401-317bb08e3772', // Seymons
  9394: 'https://metabase.skindr.com/public/dashboard/73c35773-69a2-4e1e-838f-fcc41131f872', // Wallaeys
  8562: 'https://metabase.skindr.com/public/dashboard/ed6b39b0-acaa-4721-a528-99619a4d11ab', // Gmelig Meijling
  34996: 'https://metabase.skindr.com/public/dashboard/5893f2e6-7c70-4609-a4a0-3eae27054708', // Dr. Herm Martens
  35750: 'https://metabase.skindr.com/public/dashboard/011c3ba4-e89a-489e-af8e-8e8d237c7d00', // Dr. Michelle Mertens
  67768: 'https://metabase.skindr.com/public/dashboard/3bf7e5b7-4197-4928-92ad-279aed48e898', // Dr. Farida Benhadou
  93659: 'https://metabase.skindr.com/public/dashboard/2d9772cb-0917-468c-ae2d-1f8f11285591', // Dr. Fanny Anthuenis
};

export function DashboardPage() {
  const { user } = useContext(UserContext);
  const dashboardIndex = isReactAppEnvProduction() ? (user as User).id : 2;

  return (
    <DoctorShell>
      <iframe
        title={(user as User).first_name}
        src={doctorDashboardMap[dashboardIndex]}
        frameBorder="0"
        className="w-full h-full"
      />
    </DoctorShell>
  );
}
