import { useMutation } from '@apollo/client';
import { Menu, Transition } from '@headlessui/react';
import { LockClosedIcon } from '@heroicons/react/outline';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { SilentCloseConsultationProps } from './SilentCloseConsultation.types';
import { Button } from '../../../../common/form/components';
import { classNames } from '../../../../common/utils/style';
import { SILENT_CLOSE_CONSULTATION } from '../../../graphql/mutations/consultation';
import { ConsultationDetailParams } from '../ConsultationDetail/ConsultationDetail.types';

export const SilentCloseConsultation = (props: SilentCloseConsultationProps) => {
  const { consultationUpdateCallback, disabled } = props;
  const { t } = useTranslation();
  const [silentCloseConsultation] = useMutation(SILENT_CLOSE_CONSULTATION);
  const { id: consultationId } = useParams<ConsultationDetailParams>();

  const onClickHandler = async () => {
    const response = await silentCloseConsultation({
      variables: {
        consultationId,
      },
    });

    if (response.data.silentCloseConsultation) {
      consultationUpdateCallback(response.data.silentCloseConsultation);
    } else if (response.errors) {
      console.error(response.errors);
    }
  };

  return (
    <Menu as="div" className="relative self-center">
      <Menu.Button className="mt-1" disabled={disabled}>
        <LockClosedIcon className={classNames('h-5 w-5 text-gray-400', disabled && 'opacity-50')} />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-30 top-0 left-full ml-3 md:right-full md:left-auto md:mr-3 md:ml-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <h3 className="px-4 py-2 block text-sm font-medium leading-6 text-gray-900">
            {t('doctor:consultation:silent_close:title')}
          </h3>
          <div className="flex flex-col">
            <p className="px-4 py-2 text-sm">{t('doctor:consultation:silent_close:description')}</p>
            <div className="px-4 py-2 flex justify-end">
              <Menu.Item>
                <Button variant="secondary" label={t('common:no')} />
              </Menu.Item>
              <Menu.Item>
                <Button className="ml-4" label={t('common:yes')} onClick={onClickHandler} />
              </Menu.Item>
            </div>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
