import { useQuery } from '@apollo/client';
import { ChangeEventHandler, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchSkincareProductsProps } from './SearchSkincareProducts.types';
import SkincareProduct from './SelectedSkincareProduct';
import SkincareProductResults from './SkincareProductResults';
import { useSearchSkincareProducts } from '../../../../common/context/SearchSkincareProductsContext';
import { useWindowClick } from '../../../../common/utils/window-events';
import { GET_SKINCARE_PRODUCTS } from '../../../graphql/models/SkincareProduct';
import { SkincareProduct as SkincareProductType } from '../../../graphql/types';

export const SearchSkincareProducts = (props: SearchSkincareProductsProps) => {
  const { defaultSelectedProducts, selectedProductsUpdateCallback } = props;
  const { t } = useTranslation();
  const { state, dispatch } = useSearchSkincareProducts();
  useQuery(GET_SKINCARE_PRODUCTS, {
    onCompleted: (data) => {
      dispatch({ type: 'SET_FIELDS', payload: { allProducts: data.getSkincareProducts } });
    },
  });
  const skincareProductSearchInputRef = useRef<HTMLInputElement>(null);

  const windowClickCallback = useCallback(
    (event: MouseEvent) => {
      if (state.showSearchResults) {
        const searchInputRefElement = skincareProductSearchInputRef?.current;

        if (!searchInputRefElement || searchInputRefElement.contains(event.target as Node)) {
          return;
        }

        dispatch({ type: 'SET_FIELDS', payload: { showSearchResults: false } });
      }
    },
    [state.showSearchResults]
  );
  useWindowClick(windowClickCallback);

  useEffect(() => {
    if (defaultSelectedProducts && defaultSelectedProducts.length > 0) {
      dispatch({ type: 'SET_FIELDS', payload: { selectedProducts: defaultSelectedProducts } });
    }
  }, []);

  useEffect(() => {
    let filteredProducts = state.allProducts;

    if (state.selectedProducts.length > 0) {
      filteredProducts = state.allProducts.filter((product) => {
        return !state.selectedProducts.some((selectedProduct) => selectedProduct.id === product.id);
      });
    }

    if (state.searchValue !== '') {
      filteredProducts = filteredProducts.filter((product) =>
        product.title.toLowerCase().includes(state.searchValue.toLowerCase())
      );
    }

    dispatch({ type: 'SET_FIELDS', payload: { filteredProducts } });
  }, [state.allProducts, state.searchValue, state.selectedProducts]);

  useEffect(() => {
    if (selectedProductsUpdateCallback) {
      selectedProductsUpdateCallback(state.selectedProducts);
    }
  }, [state.selectedProducts]);

  const onChangeHandler: ChangeEventHandler<HTMLInputElement> = async (event) => {
    const value = event.target.value;
    dispatch({ type: 'SET_FIELDS', payload: { searchValue: value } });
  };

  const productClickHandler = (product: SkincareProductType) => {
    dispatch({
      type: 'SET_FIELDS',
      payload: { showSearchResults: false, searchValue: '', selectedProducts: [...state.selectedProducts, product] },
    });
  };

  const productRemoveHandler = (product: SkincareProductType) => {
    dispatch({
      type: 'SET_FIELDS',
      payload: {
        selectedProducts: state.selectedProducts.filter((selectedProduct) => selectedProduct.id !== product.id),
      },
    });
  };

  return (
    <>
      <h3>{t('doctor:consultation:search_skincare_products:label')}</h3>
      <div className="relative">
        <input
          ref={skincareProductSearchInputRef}
          type="search"
          value={state.searchValue}
          placeholder={t('doctor:consultation:search_skincare_products:input_placeholder')}
          onChange={onChangeHandler}
          className="w-full appearance-none border focus:outline-none border-gray-300 rounded-md focus:ring-teal-500 focus:border-teal-500"
          onFocus={() => dispatch({ type: 'SET_FIELDS', payload: { showSearchResults: true } })}
        />

        {state.showSearchResults && (
          <SkincareProductResults products={state.filteredProducts} productClickCallback={productClickHandler} />
        )}
      </div>
      {state.selectedProducts && (
        <ul className="flex flex-wrap gap-1">
          {state.selectedProducts.map((product: SkincareProductType) => (
            <li key={`selected-product-${product.id}`}>
              <SkincareProduct product={product} productRemoveHandler={productRemoveHandler} />
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
