import axios, { AxiosResponse } from 'axios';

import { GetRequestInput, PostRequestInput, PutRequestInput, DeleteRequestInput } from './types';
import { Consultation } from '../../doctor/graphql/types';
import {
  SignUpPatientInput,
  SignUpDoctorInput,
  LogInInput,
  ForgotPasswordInput,
  ResetPasswordInput,
  User,
  UserSourceInput,
} from '../authentication/types';
import {
  Doctor,
  UpdateConsultationInput,
  FeedbackInput,
  VerifyDoctorInput,
  PatientInvitationInput,
  ConsultationTypeInput,
  ConsultationReadStatus,
  DraftConsultationInput,
  MessageTextInput,
  MessageImageInput,
  MessageFileInput,
  MatchDoctorInput,
} from '../consultation/types';
import { SearchInput } from '../icd10/types';
import { ProblemInput, Problem, Picture } from '../problem/types';
import { SettingsInput, DoctorSettingsInput, PatientSettingsInput, OtherPatientSettingsInput } from '../settings/types';
import { DoctorCannedResponse, CannedResponseInput, CannedResponse } from '../template/types';

export async function signUpPatient(signUpInput: SignUpPatientInput): Promise<AxiosResponse> {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URI}/api/register/patient`,
    data: signUpInput,
  });
}

export async function signUpDoctor(signUpInput: SignUpDoctorInput): Promise<AxiosResponse> {
  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URI}/api/register/doctor`,
    data: signUpInput,
  });
}

export async function logIn(logInInput: LogInInput): Promise<AxiosResponse> {
  const { email, password } = logInInput;

  const data = {
    grant_type: 'password',
    client_id: process.env.REACT_APP_API_CLIENT_ID,
    client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
    username: email,
    password,
  };

  return await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URI}/oauth/token`,
    data,
  });
}

export async function forgotPassword(forgotPasswordInput: ForgotPasswordInput): Promise<AxiosResponse> {
  return await post({ endpoint: `/api/forgot-password`, data: forgotPasswordInput });
}

export async function resetPassword(resetPasswordInput: ResetPasswordInput): Promise<AxiosResponse> {
  return await post({ endpoint: `/api/reset-password`, data: resetPasswordInput });
}

export async function getAuthenticatedUser(accessToken: string): Promise<AxiosResponse> {
  return await get({ endpoint: '/api/users/me', access_token: accessToken });
}

export async function getOtherPatients(user: User): Promise<AxiosResponse> {
  const { access_token } = user;
  return await get({ endpoint: '/api/users/other', access_token });
}

export async function updateUserSource(user: User, userSourceInput: UserSourceInput): Promise<AxiosResponse> {
  const { access_token } = user;
  return await put({ endpoint: '/api/users/source', data: userSourceInput, access_token });
}

export async function matchDoctor(user: User, matchDoctorInput: MatchDoctorInput): Promise<AxiosResponse> {
  const { access_token } = user;
  const params = new URLSearchParams();
  params.set('country', matchDoctorInput.country);

  if (matchDoctorInput.doctor_id) {
    params.set('doctor_id', matchDoctorInput.doctor_id.toString());
  }

  if (matchDoctorInput.previous_consultation_id) {
    params.set('previous_consultation_id', matchDoctorInput.previous_consultation_id.toString());
  }

  if (matchDoctorInput.other_patient_id) {
    params.set('other_patient_id', matchDoctorInput.other_patient_id.toString());
  }

  return await get({ endpoint: `/api/users/match-doctor?${params}`, access_token });
}

export async function updateSettings(user: User, id: number, settingsInput: SettingsInput): Promise<AxiosResponse> {
  const { access_token } = user;
  return await putWithAttachment({ endpoint: `/api/settings/${id}`, data: settingsInput, access_token });
}

export async function updateDoctorSettings(
  user: User,
  id: number,
  settingsInput: DoctorSettingsInput
): Promise<AxiosResponse> {
  const { access_token } = user;
  return await put({ endpoint: `/api/settings/doctor/${id}`, data: settingsInput, access_token });
}

export async function updatePatientSettings(user: User, settingsInput: PatientSettingsInput): Promise<AxiosResponse> {
  const { access_token } = user;
  return await put({ endpoint: `/api/settings/patient`, data: settingsInput, access_token });
}

export async function updateOtherPatientSettings(
  user: User,
  settingsInput: OtherPatientSettingsInput
): Promise<AxiosResponse> {
  const { access_token } = user;
  return await put({ endpoint: `/api/settings/other-patient`, data: settingsInput, access_token });
}

export async function updatePartialPatientSettings(
  user: User,
  settingsInput: PatientSettingsInput
): Promise<AxiosResponse> {
  const { access_token } = user;
  return await put({ endpoint: `/api/settings/patient/partial`, data: settingsInput, access_token });
}

export async function storeProblem(user: User, problemInput: ProblemInput): Promise<AxiosResponse> {
  const { access_token } = user;
  return await post({ endpoint: `/api/problems`, data: problemInput, access_token });
}

export async function updateProblem(user: User, ProblemId: number, problemInput: ProblemInput): Promise<AxiosResponse> {
  const { access_token } = user;
  return await put({ endpoint: `/api/problems/${ProblemId}`, data: problemInput, access_token });
}

export async function storeProblemPictures(
  user: User,
  problemId: number,
  problemInput: ProblemInput
): Promise<AxiosResponse> {
  const { access_token } = user;
  return await postWithAttachment({
    endpoint: `/api/problems/${problemId}/problem-pictures`,
    data: problemInput,
    access_token,
  });
}

export async function updateProblemPictures(
  user: User,
  problem: Problem,
  problemInput: ProblemInput
): Promise<AxiosResponse> {
  const { access_token } = user;
  const { id } = problem;
  return await putWithAttachment({
    endpoint: `/api/problems/${id}/problem-pictures`,
    data: problemInput,
    access_token,
  });
}

export async function verifyDoctor(
  user: User,
  doctor: Doctor,
  verifyDoctorInput: VerifyDoctorInput
): Promise<AxiosResponse> {
  const { access_token } = user;
  const { id } = doctor;

  return await put({ endpoint: `/api/doctors/${id}/verify`, data: verifyDoctorInput, access_token });
}

export async function storeConsultationWithDoctorMatching(
  user: User,
  consultationInput: ConsultationTypeInput
): Promise<AxiosResponse> {
  const { access_token } = user;
  return await post({ endpoint: `/api/consultations`, data: consultationInput, access_token });
}

export async function updateConsultation(
  user: User,
  consultation: Consultation,
  consultationInput: UpdateConsultationInput
): Promise<AxiosResponse> {
  const { access_token } = user;
  const { id } = consultation;

  return await put({ endpoint: `/api/consultations/${id}`, data: consultationInput, access_token });
}

export async function updateConsultationByPatient(
  user: User,
  consultationId: number,
  consultationTypeInput: ConsultationTypeInput
): Promise<AxiosResponse> {
  const { access_token } = user;
  return await put({
    endpoint: `/api/consultations/${consultationId}/update-by-patient`,
    data: consultationTypeInput,
    access_token,
  });
}

export async function deleteConsultationDraft(user: User, consultationId: number): Promise<AxiosResponse> {
  const { access_token } = user;

  return await deleteRequest({
    endpoint: `/api/consultations/${consultationId}/draft`,
    access_token,
  });
}

export async function closeConsultation(
  user: User,
  consultation: Consultation,
  consultationInput: UpdateConsultationInput
): Promise<AxiosResponse> {
  const { access_token } = user;
  const { id } = consultation;

  return await put({ endpoint: `/api/consultations/${id}/close`, data: consultationInput, access_token });
}

export async function toggleConsultationReadStatus(
  user: User,
  consultation: Consultation,
  consultationReadStatus: ConsultationReadStatus
): Promise<AxiosResponse> {
  const { access_token } = user;
  const { id } = consultation;

  return await put({
    endpoint: `/api/consultations/${id}/read-status`,
    data: {
      status: consultationReadStatus,
    },
    access_token,
  });
}

export async function storePrescriptions(
  user: User,
  consultationId: string,
  prescriptions: File[]
): Promise<AxiosResponse[]> {
  const { access_token } = user;

  return await axios.all(
    prescriptions.map(async (prescription: File) => {
      return await postWithAttachment({
        endpoint: `/api/consultations/${consultationId}/prescriptions`,
        data: { file: prescription },
        access_token,
      });
    })
  );
}

export async function deletePrescription(
  user: User,
  consultationId: string,
  prescriptionId: string
): Promise<AxiosResponse> {
  const { access_token } = user;

  return await deleteRequest({
    endpoint: `/api/consultations/${consultationId}/prescriptions/${prescriptionId}`,
    access_token,
  });
}

export async function deleteMessage(user: User, consultationId: string, messageId: string): Promise<AxiosResponse> {
  const { access_token } = user;

  return await deleteRequest({
    endpoint: `/api/consultations/${consultationId}/messages/${messageId}`,
    access_token,
  });
}

export async function storeExtraPictures(
  user: User,
  problemId: number,
  pictures: Blob[],
  consultation?: Consultation
): Promise<AxiosResponse[]> {
  const { access_token } = user;

  return await axios.all(
    pictures.map(async (picture: Blob) => {
      let data: { picture: Blob; consultation_id?: string } = { picture };
      if (consultation) {
        data = { picture, consultation_id: consultation.id };
      }

      return await postWithAttachment({
        endpoint: `/api/problems/${problemId}/extra-pictures`,
        data,
        access_token,
      });
    })
  );
}

export async function updateExtraPictures(
  user: User,
  problemId: number,
  picturesToUpdate: Picture[],
  pictures: Blob[]
): Promise<AxiosResponse[]> {
  const { access_token } = user;
  return await axios.all(
    picturesToUpdate.map(async (picture: Picture, index: number) => {
      const { id: picture_id } = picture;

      return await putWithAttachment({
        endpoint: `/api/problems/${problemId}/extra-pictures/${picture_id}`,
        data: { picture: pictures[index] },
        access_token,
      });
    })
  );
}

export async function getDoctorSettings(user: User): Promise<AxiosResponse> {
  const { access_token } = user;
  return await get({ endpoint: `/api/settings/doctor`, access_token });
}

export async function getPatientSettings(user: User): Promise<AxiosResponse> {
  const { access_token } = user;
  return await get({ endpoint: `/api/settings/patient`, access_token });
}

export async function getDoctor(user: User, id: number): Promise<AxiosResponse> {
  if (user) {
    const { access_token } = user;
    return await get({ endpoint: `/api/doctors/${id}`, access_token });
  }

  return await get({ endpoint: `/api/doctors/${id}` });
}

export async function getDoctors(): Promise<AxiosResponse> {
  return await get({ endpoint: `/api/doctors` });
}

export async function getAllDoctors(user: User): Promise<AxiosResponse> {
  const { access_token } = user;
  return await get({ endpoint: `/api/doctors/all`, access_token });
}

export async function getDataExport(user: User): Promise<AxiosResponse> {
  const { access_token } = user;
  return await download({ endpoint: '/api/settings/export', access_token });
}

export async function getDoctorConsultation(user: User, id: string): Promise<AxiosResponse> {
  const { access_token } = user;
  return await get({ endpoint: `/api/consultations/doctor/${id}`, access_token });
}

export async function getPatientConsultations(user: User): Promise<AxiosResponse> {
  const { access_token } = user;
  return await get({ endpoint: '/api/consultations/patient', access_token });
}

export async function getPatients(user: User, query: string): Promise<AxiosResponse> {
  const { access_token } = user;
  return await get({ endpoint: `/api/patients?query=${query}`, access_token });
}

export async function getPatient(user: User, id: number): Promise<AxiosResponse> {
  const { access_token } = user;
  return await get({ endpoint: `/api/patients/${id}`, access_token });
}

export async function getPreviousConsultations(user: User): Promise<AxiosResponse> {
  const { access_token } = user;

  return await get({ endpoint: '/api/users/previous-consultations', access_token });
}

export async function getCannedResponses(user: User): Promise<AxiosResponse> {
  const { access_token } = user;
  return await get({ endpoint: '/api/cannedresponses/doctor', access_token });
}

export async function getDoctorCannedResponse(user: User, id: number): Promise<AxiosResponse> {
  const { access_token } = user;
  return await get({ endpoint: `/api/cannedresponses/doctor/${id}?type=doctor`, access_token });
}

export async function getDefaultCannedResponse(user: User, id: number): Promise<AxiosResponse> {
  const { access_token } = user;
  return await get({ endpoint: `/api/cannedresponses/doctor/${id}`, access_token });
}

export async function storeCannedResponse(
  user: User,
  cannedResponseInput: CannedResponseInput
): Promise<AxiosResponse> {
  const { access_token } = user;
  return await post({ endpoint: `/api/cannedresponses/doctor`, data: cannedResponseInput, access_token });
}

export async function storeDefaultCannedResponse(
  user: User,
  cannedResponseInput: CannedResponseInput
): Promise<AxiosResponse> {
  const { access_token } = user;
  return await post({ endpoint: `/api/cannedresponses`, data: cannedResponseInput, access_token });
}

export async function updateCannedResponse(
  user: User,
  cannedResponse: DoctorCannedResponse,
  cannedResponseInput: CannedResponseInput
): Promise<AxiosResponse> {
  const { access_token } = user;
  const { id } = cannedResponse;
  return await put({ endpoint: `/api/cannedresponses/doctor/${id}`, data: cannedResponseInput, access_token });
}

export async function updateDefaultCannedResponse(
  user: User,
  cannedResponse: CannedResponse,
  cannedResponseInput: CannedResponseInput
): Promise<AxiosResponse> {
  const { access_token } = user;
  const { id } = cannedResponse;
  return await put({ endpoint: `/api/cannedresponses/${id}`, data: cannedResponseInput, access_token });
}

export async function deleteCannedResponse(user: User, cannedResponse: DoctorCannedResponse): Promise<AxiosResponse> {
  const { access_token } = user;
  const { id } = cannedResponse;
  return await deleteRequest({ endpoint: `/api/cannedresponses/doctor/${id}`, access_token });
}

export async function deleteDefaultCannedResponse(user: User, cannedResponse: CannedResponse): Promise<AxiosResponse> {
  const { access_token } = user;
  const { id } = cannedResponse;
  return await deleteRequest({ endpoint: `/api/cannedresponses/${id}`, access_token });
}

export async function getConsultation(user: User, id: number): Promise<AxiosResponse> {
  const { access_token } = user;
  return await get({ endpoint: `/api/consultations/${id}`, access_token });
}

export async function storeMessage(
  user: User,
  consultationId: string,
  messageInput: MessageTextInput | MessageImageInput | MessageFileInput
): Promise<AxiosResponse> {
  const { access_token } = user;
  return await post({ endpoint: `/api/consultations/${consultationId}/messages`, data: messageInput, access_token });
}

export async function storeFeedback(
  user: User,
  consultation: Consultation,
  feedbackInput: FeedbackInput
): Promise<AxiosResponse> {
  const { access_token } = user;
  const { id } = consultation;
  return await post({ endpoint: `/api/consultations/${id}/feedback`, data: feedbackInput, access_token });
}

export async function storePatientInvitation(
  user: User,
  patientInvitationInput: PatientInvitationInput
): Promise<AxiosResponse> {
  const { access_token } = user;
  return await post({ endpoint: `/api/patients/invite`, data: patientInvitationInput, access_token });
}

export async function storePayment(user: User, consultation: Consultation): Promise<AxiosResponse> {
  const { access_token } = user;
  const { id } = consultation;
  return await post({ endpoint: `/api/consultations/${id}/payments`, data: {}, access_token });
}

export async function getMessages(user: User, consultation: Consultation): Promise<AxiosResponse> {
  const { access_token } = user;
  const { id } = consultation;
  return await get({ endpoint: `/api/consultations/${id}/messages`, access_token });
}

export async function searchICD10Codes(user: User, searchInput: SearchInput): Promise<AxiosResponse> {
  const { access_token } = user;
  const { query } = searchInput;
  return await get({ endpoint: `/api/icd10codes/search?query=${query}`, access_token });
}

export async function getICD10CodeSuggestions(user: User): Promise<AxiosResponse> {
  const { access_token } = user;
  return await get({ endpoint: '/api/icd10codes/suggestions', access_token });
}

export async function searchCannedResponses(user: User, searchInput: SearchInput): Promise<AxiosResponse> {
  const { access_token } = user;
  const { query } = searchInput;
  return await get({ endpoint: `/api/cannedresponses/doctor/search?query=${query}`, access_token });
}

async function get(getRequestInput: GetRequestInput): Promise<AxiosResponse> {
  const { endpoint, access_token } = getRequestInput;
  const headers = getDefaultHeaders(access_token);

  return await axios({
    method: 'GET',
    headers,
    url: `${process.env.REACT_APP_API_URI}${endpoint}`,
  });
}

async function download(getRequestInput: GetRequestInput): Promise<AxiosResponse> {
  const { endpoint, access_token } = getRequestInput;
  const headers = getDefaultHeaders(access_token);

  return await axios({
    method: 'GET',
    headers,
    url: `${process.env.REACT_APP_API_URI}${endpoint}`,
    responseType: 'blob',
  });
}

async function put(putRequestInput: PutRequestInput): Promise<AxiosResponse> {
  const { endpoint, data, access_token } = putRequestInput;
  const headers = getDefaultHeaders(access_token);

  return await axios({
    method: 'PUT',
    headers,
    url: `${process.env.REACT_APP_API_URI}${endpoint}`,
    data,
  });
}

async function post(postRequestInput: PostRequestInput): Promise<AxiosResponse> {
  const { endpoint, data, access_token } = postRequestInput;
  const headers = getDefaultHeaders(access_token);

  return await axios({
    method: 'POST',
    headers,
    url: `${process.env.REACT_APP_API_URI}${endpoint}`,
    data,
  });
}

async function deleteRequest(deleteRequestInput: DeleteRequestInput): Promise<AxiosResponse> {
  const { endpoint, access_token } = deleteRequestInput;
  const headers = getDefaultHeaders(access_token);

  return await axios({
    method: 'DELETE',
    headers,
    url: `${process.env.REACT_APP_API_URI}${endpoint}`,
  });
}

async function postWithAttachment(postRequestInput: PostRequestInput): Promise<AxiosResponse> {
  const { endpoint, data, access_token } = postRequestInput;
  const formData = new FormData();
  Object.keys(data).forEach((key) => data[key] && formData.append(key, data[key]));

  let headers = getDefaultHeaders(access_token);
  headers = {
    ...headers,
    'Content-Type': `multipart/form-data`,
  };

  return await axios({
    method: 'POST',
    headers,
    url: `${process.env.REACT_APP_API_URI}${endpoint}`,
    data: formData,
  });
}

async function putWithAttachment(putRequestInput: PutRequestInput): Promise<AxiosResponse> {
  const { endpoint, data, access_token } = putRequestInput;
  const formData = new FormData();
  formData.append('_method', 'PUT');
  Object.keys(data).forEach((key) => data[key] && formData.append(key, data[key]));

  let headers = getDefaultHeaders(access_token);
  headers = {
    ...headers,
    'Content-Type': `multipart/form-data`,
  };

  return await axios({
    method: 'POST',
    headers,
    url: `${process.env.REACT_APP_API_URI}${endpoint}`,
    data: formData,
  });
}

export async function getDraftConsultation(user: User): Promise<AxiosResponse> {
  const { access_token } = user;
  return await get({ endpoint: '/api/draftconsultations', access_token });
}

export async function upsertDraftConsultation(
  user: User,
  draftConsultationInput: DraftConsultationInput
): Promise<AxiosResponse> {
  const { access_token } = user;
  return await putWithAttachment({
    endpoint: '/api/draftconsultations',
    data: draftConsultationInput,
    access_token,
  });
}

export async function validateDiscount(user: User, consultationId: number, code: string): Promise<AxiosResponse> {
  const { access_token } = user;
  return await post({
    endpoint: `/api/consultations/${consultationId}/discount/validate`,
    data: { code },
    access_token,
  });
}

export async function checkoutDiscount(user: User, consultationId: number, code: string): Promise<AxiosResponse> {
  const { access_token } = user;
  return await post({
    endpoint: `/api/consultations/${consultationId}/discount/checkout`,
    data: { code },
    access_token,
  });
}

export async function toggleReopenStatus(user: User, consultationId: string): Promise<AxiosResponse> {
  const { access_token } = user;
  return await put({
    endpoint: `/api/consultations/${consultationId}/toggle-reopen-status`,
    data: {},
    access_token,
  });
}

const getDefaultHeaders = (accessToken?: string) => {
  let headers: any = {
    'X-Temporary-Urls': 'true',
  };

  if (accessToken) {
    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return headers;
};
