import { useTranslation } from 'react-i18next';

import { SkincareProductResultsProps } from './SkincareProductResults.types';
import { SkincareProduct as SkincareProductType } from '../../../../graphql/types';

export const SkincareProductResults = (props: SkincareProductResultsProps) => {
  const { products, productClickCallback } = props;
  const { t } = useTranslation();

  return (
    <div className="absolute bottom-full left-0 right-0 max-h-96 mb-2 bg-teal-200 overflow-auto rounded-md shadow-md">
      {products.length < 1 && (
        <span className="block px-4 py-2">{t('doctor:consultation:search_skincare_products:no_results')}</span>
      )}
      {products.length > 0 && (
        <ul>
          {products.map((product: SkincareProductType) => (
            <li key={`product-result-${product.id}`}>
              <button
                className="block px-4 py-2 w-full text-teal-800 hover:bg-teal-300 font-medium text-left"
                onClick={() => {
                  productClickCallback(product);
                }}
              >
                {product.title}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
