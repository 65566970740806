import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DiagnosisTreatmentModalProps } from './DiagnosisTreatmentModalProps.types';
import ModalWrapper from '../../../../common/ModalWrapper';
import TinyRichTextEditor from '../../../../common/TinyRichTextEditor';
import { EMPTY_CONTENT } from '../../../../common/constants/message';
import { useDiagnosisTreatment } from '../../../../common/context/DiagnosisTreatmentContext';
import { Button } from '../../../../common/form/components';
import { GENERATE_DIAGNOSIS_TREATMENT, SEND_DIAGNOSIS_TREATMENT } from '../../../graphql/mutations/diagnosis_treatment';

export const DiagnosisTreatmentModal = (props: DiagnosisTreatmentModalProps) => {
  const { consultationId, createMessageCallback, defaultPatientIntro, diagnosisTreatment, visibility, setVisibility } =
    props;
  const { t } = useTranslation();
  const { state, dispatch } = useDiagnosisTreatment();
  const [generateDiagnosisTreatment] = useMutation(GENERATE_DIAGNOSIS_TREATMENT);
  const [sendDiagnosisTreatment] = useMutation(SEND_DIAGNOSIS_TREATMENT);

  useEffect(() => {
    if (visibility) {
      triggerGenerateDiagnosisTreatment();
    }
  }, [visibility]);

  const triggerGenerateDiagnosisTreatment = async () => {
    const response = await generateDiagnosisTreatment({
      variables: { consultationId, diagnosisTreatment },
    });

    if (response.data.generateConsultationDiagnosisTreatment) {
      const { diagnosis, background, treatment, next_steps } = response.data.generateConsultationDiagnosisTreatment;
      dispatch({
        type: 'SET_FIELDS',
        payload: {
          loading: false,
          patientIntro: defaultPatientIntro,
          diagnosis,
          background,
          treatment,
          nextSteps: next_steps,
        },
      });
    } else if (response.errors) {
      errorHandler(response.errors);
    }
  };

  const onCloseModalHandler = (modalVisible: boolean): void => {
    if (state.loading) {
      return;
    }

    setVisibility(modalVisible);
    setTimeout(() => {
      dispatch({ type: 'CLEAR' });
    }, 200); // Transition time
  };

  const onChangeHandler = (name: string, value: string) => {
    dispatch({ type: 'SET_FIELDS', payload: { [name]: value } });
  };

  const onSendHandler = async () => {
    const response = await sendDiagnosisTreatment({
      variables: {
        input: {
          consultation_id: consultationId,
          patient_intro: state.patientIntro !== EMPTY_CONTENT ? state.patientIntro : null,
          diagnosis: state.diagnosis !== EMPTY_CONTENT ? state.diagnosis : null,
          treatment: state.treatment !== EMPTY_CONTENT ? state.treatment : null,
          background: state.background !== EMPTY_CONTENT ? state.background : null,
          next_steps: state.nextSteps !== EMPTY_CONTENT ? state.nextSteps : null,
        },
      },
    });

    if (response.data.sendConsultationDiagnosisTreatment) {
      createMessageCallback(response.data.sendConsultationDiagnosisTreatment);
      onCloseModalHandler(false);
    } else if (response.errors) {
      errorHandler(response.errors);
    }

    onCloseModalHandler(false);
  };

  const errorHandler = (errors: any) => {
    // TODO: error handling
    console.log(errors);
  };

  return (
    <ModalWrapper
      title={t('doctor:consultation:diagnosis_treatment:modal:title')}
      setVisibility={onCloseModalHandler}
      visible={visibility}
      size="large"
    >
      {state.loading && <p>loading…</p>}
      {!state.loading && (
        <div className="space-y-2">
          <TinyRichTextEditor
            label={t('doctor:consultation:diagnosis_treatment:modal:form:patient_intro')}
            name="patientIntro"
            onChangeCallback={onChangeHandler}
            value={state.patientIntro}
          />
          <TinyRichTextEditor
            label={t('doctor:consultation:diagnosis_treatment:modal:form:diagnosis')}
            name="diagnosis"
            onChangeCallback={onChangeHandler}
            value={state.diagnosis}
          />
          <TinyRichTextEditor
            label={t('doctor:consultation:diagnosis_treatment:modal:form:background')}
            name="background"
            onChangeCallback={onChangeHandler}
            value={state.background}
          />
          <TinyRichTextEditor
            label={t('doctor:consultation:diagnosis_treatment:modal:form:treatment')}
            name="treatment"
            onChangeCallback={onChangeHandler}
            value={state.treatment}
          />
          <TinyRichTextEditor
            label={t('doctor:consultation:diagnosis_treatment:modal:form:next_steps')}
            name="nextSteps"
            onChangeCallback={onChangeHandler}
            value={state.nextSteps}
          />

          <div className="flex">
            <Button label={t('doctor:consultation:diagnosis_treatment:modal:form:send_btn')} onClick={onSendHandler} />
            <Button
              className="ml-4"
              label={t('doctor:consultation:diagnosis_treatment:modal:form:cancel_btn')}
              variant="secondary"
              onClick={() => {
                onCloseModalHandler(false);
              }}
            />
          </div>
        </div>
      )}
    </ModalWrapper>
  );
};
