import { XIcon, CheckIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';

import type { ConversationWrapperProps } from './ConversationWrapper.types';
import { STATUS_READ } from '../../constants/message';
import { formatReadable } from '../../utils/date';
import { classNames } from '../../utils/style';

export function ConversationWrapper(props: ConversationWrapperProps) {
  const { children, deleteCallbackHandler, isUser, item, readonly } = props;
  const [hasUnreadReadReceipts, setHasUnreadReadReceipts] = useState(true);

  useEffect(() => {
    if ('body_type' in item && !!item.read_receipts?.length) {
      const readReadReceipt = item.read_receipts?.find((readReceipt) => readReceipt.status === STATUS_READ);
      if (readReadReceipt !== undefined) {
        setHasUnreadReadReceipts(false);
      }
    }
  }, [item]);

  return (
    <div className={classNames('flex gap-4 my-4', isUser ? 'justify-end' : 'justify-start')}>
      <img
        className={classNames('h-12 w-12 rounded-full object-cover', isUser && 'order-1')}
        src={item.sender?.avatar}
        alt={`${item.sender?.first_name} ${item.sender?.last_name}`}
      />
      <div>
        <div className={classNames('max-w-2xl relative', isUser && 'flex justify-end')}>
          {children}

          {isUser && deleteCallbackHandler && !readonly && !item.deleted_at && (
            <button
              type="button"
              onClick={() => {
                deleteCallbackHandler && deleteCallbackHandler(item);
              }}
              className={classNames(
                'absolute top-2 right-2 mt-0.5 h-4 w-4 inline-flex items-center justify-center rounded-full focus:outline-none',
                'body_type' in item
                  ? 'text-teal-400 hover:text-teal-500 focus:text-teal-500'
                  : 'text-primary-normal hover:text-primary-dark focus:text-primary-dark'
              )}
            >
              <XIcon className="h-6 w-6" />
            </button>
          )}
        </div>

        <p className={classNames('mt-1 text-xs text-gray-400', isUser && 'flex justify-end items-center')}>
          {isUser && 'body_type' in item && (
            <span
              className={classNames(
                'inline-flex -mt-0.5 mr-0.5 ',
                hasUnreadReadReceipts ? 'text-gray-400' : 'text-indigo-400'
              )}
            >
              <CheckIcon className="h-4 w-4" />
              <CheckIcon className="h-4 w-4 -ml-2" />
            </span>
          )}
          {formatReadable(item.created_at)}
        </p>
      </div>
    </div>
  );
}
